import React from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import BackToTop from "./BackToTop";
export default function Service() {
  const navigate = useNavigate();
  const handleSeeAll = () => {
    navigate("/services");
  };
  return (
    <div>
      {/* <!-- service section --> */}

      <section class="service_section layout_padding">
        <div class="service_container">
          <div class="container ">
            <div class="heading_container heading_center">
              <h2>
                Our <span>Services</span>
              </h2>
              <p>
                At
                <h5>
                  <b>King Tech Solution</b>
                </h5>
                We provide comprehensive printer services to ensure your
                business operations run smoothly. Whether it's troubleshooting,
                repair, or maintenance, we’ve got you covered. Our expert team
                is equipped to handle all types of printer issues with
                efficiency and precision.
              </p>
            </div>
            <div class="row">
              <div class="col-md-6 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/s1.png" alt="" />
                  </div>
                  <div class="detail-box">
                    <h5>Printer Repair</h5>
                    <p>
                      We provide fast and reliable repair services for all
                      printer brands and models, including laserjet, inkjet,
                      barcode, and dot matrix printers, ensuring minimal
                      downtime and maximum efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/s2.png" alt="" />
                  </div>
                  <div class="detail-box">
                    <h5>Maintenance & Servicing</h5>
                    <p>
                      Our regular maintenance services keep your printers in
                      optimal condition, reducing the risk of unexpected
                      breakdowns and improving long-term performance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/s3.png" alt="" />
                  </div>
                  <div class="detail-box">
                    <h5>Printer Setup & Installation</h5>
                    <p>
                      Enjoy hassle-free installation and configuration of
                      printers, scanners, and multifunction devices, perfectly
                      suited to businesses of any size.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/s4.png" alt="" />
                  </div>
                  <div class="detail-box">
                    <h5>Spare Parts & Accessories</h5>
                    <p>
                      We supply genuine printer spare parts and accessories,
                      ensuring your equipment runs smoothly for years to come.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{cursor:'pointer'}} class="btn-box">
              <a onClick={handleSeeAll}>See All</a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- end service section --> */}
      {/* <!-- why us section --> */}

      <section class="why_us_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>Why Choose Us</h2>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="box ">
                <div class="img-box">
                  <img src="images/w1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Customer Satisfaction</h5>
                  <p>
                    Highlight your focus on delivering quality and ensuring
                    customer success.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box ">
                <div class="img-box">
                  <img src="images/w2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Quick Repair Process</h5>
                  <p>
                    Focus on how your repairs ensure smooth and uninterrupted
                    workflows.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="box ">
                <div class="img-box">
                  <img src="images/w3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Low Price Guarantee</h5>
                  <p>Affordable rates without compromising on quality </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      {/* <!-- end why us section --> */}
      <BackToTop/>
    </div>
  );
}
