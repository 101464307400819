import React from 'react';
import PropTypes from 'prop-types';

const PrinterTypeSelector = ({ selectedType, onTypeClick }) => {
  return (
    <div className="printer-type-container">
      <h6 className="printer-type-heading">Choose a type of printer</h6>
      <div className="list-group printer-type-list">
        {['Laserjet', 'Inkjet', 'Barcode', 'DotMatrix', 'Thurmul', 'Scanjet'].map((type) => (
          <button
            key={type}
            className={`list-group-item list-group-item-action printer-type-btn ${selectedType === type ? 'active-type' : ''}`}
            onClick={() => onTypeClick(type)}
          >
            {type}
          </button>
        ))}
      </div>
    </div>
  );
};

PrinterTypeSelector.propTypes = {
  selectedType: PropTypes.string,
  onTypeClick: PropTypes.func.isRequired,
};

export default PrinterTypeSelector;
