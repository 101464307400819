import React from "react";
import Slider from "./Slider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handellogo=()=>{
    navigate('/')
  }

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleNavItemClick = () => {
    setIsCollapsed(true); // Collapse the navbar on item click
  };

  const navigate = useNavigate();
  const handleHome = () => {
    navigate("/");
    setExpanded(false);
  };
  const handleService = () => {
    navigate("/services");
  };

  const handleAbout = () => {
    navigate("/about");
  };
  const handleContact = () => {
    navigate("/contact");
  };
  const handleSale = () => {
    navigate("/sales");
  };

  return (
    <div>
      {/* <!-- header section strats --> */}
      <header className="header_section">
      <div className="header_top">
        <div className="container-fluid">
          <div className="top_nav_container">
            <a style={{cursor:'pointer'}} onClick={handellogo} className="navbar-brand d-none d-lg-flex">
              <span>
                <img className="logo" src="/images/LOGO.png" alt="" />
              </span>
            </a>
            <div className="contact_nav">
              <a href="">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>
                  <a href="tel:+919423762237">+91 94237 62237</a>
                </span>
              </a>
              <a href="">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>
                  <a href="mailto:kingtech232@gmail.com">kingtech232@gmail.com</a>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container">
            <a className="navbar-brand d-lg-none">
              <span>King Tech Solution</span>
            </a>

            <button
              className="navbar-toggler"
              type="button"
              onClick={handleToggle}
              aria-controls="navbarSupportedContent"
              aria-expanded={!isCollapsed}
              aria-label="Toggle navigation"
            >
              <span></span>
            </button>

            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link pl-lg-0" onClick={() => { handleNavItemClick(); handleHome(); }}>
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => { handleNavItemClick(); handleService(); }}>
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => { handleNavItemClick(); handleSale(); }}>
                    Sales
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => { handleNavItemClick(); handleAbout(); }}>
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={() => { handleNavItemClick(); handleContact(); }}>
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
      {/* <!-- end header section --> */}
      {/* <Slider/> */}
    </div>
  );
}
