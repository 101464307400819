import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from './Carousel'; // Import Carousel component
import BrandLogos from './BrandLogos';
import Loader from './Loader';
import { useState } from 'react';
import BackToTop from './BackToTop';

// Updated company descriptions with new companies
const PRINTER_COMPANIES = [
  { name: 'HP Printers',img:'/images/HP_logo_2012.png', id: 'hp', description: 'HP offers a range of high-performance Laserjet and Inkjet printers designed for personal and business use.' },
  { name: 'Canon Printers',img:'/images/canonlogo.png', id: 'canon', description: 'Canon provides innovative and reliable office and photo printers, catering to a wide variety of printing needs.' },
  { name: 'Epson Printers',img:'/images/epsonlogo.png', id: 'epson', description: 'Epson specializes in high-quality Inkjet and all-in-one printers, suitable for homes and small businesses.' },
  { name: 'Brother Printers',img:'/images/brother-logo.png', id: 'brother', description: 'Brother produces fast, cost-effective, and reliable printing solutions for business environments.' },
  { name: 'Samsung Printers',img:'/images/samsung-logo-preview.png', id: 'samsung', description: 'Samsung delivers innovative printing technology for home and office, focusing on performance and ease of use.' },
  { name: 'Ricoh Printers',img:'/images/richo-logo.png', id: 'ricoh', description: 'Ricoh offers advanced and reliable printing solutions, with a focus on sustainability and efficiency.' },
  { name: 'Sharp Printers',img:'/images/sharp.png', id: 'sharp', description: 'Sharp delivers high-performance printers that combine productivity with ease of use.' },
  { name: 'Toshiba Printers',img:'/images/toshiba.png', id: 'toshiba', description: 'Toshiba offers durable and efficient printers for businesses of all sizes, focusing on security and eco-friendliness.' },
  { name: 'Konica Minolta Printers',img:'/images/konica.png', id: 'konica_minolta', description: 'Konica Minolta provides innovative printing technology for offices, with a focus on quality and speed.' }
];

const Sales = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Use navigate for programmatic navigation
  const location = useLocation(); // Use location to monitor changes

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Replace with actual loading time or API response
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when location changes
  }, [location]);

  const handleCompanyClick = (id) => {
    // Navigate to the specific printer types page
    navigate(`/sales/${id}`, { state: { scrollToTop: true } });
  };

  const renderPrinterCompanies = () => (
    <div className="row printer-company-list">
      {PRINTER_COMPANIES.map((company, index) => (
        <div key={company.id} className="col-md-4 mb-3" style={{ animationDelay: `${index * 0.2}s` }}>
          <div className="card printer-company-card" onClick={() => handleCompanyClick(company.id)}>
            <img src={company.img} className="card-img-top printer-company-img companyimg" alt={`${company.name} logo`} />
            <div className="card-body">
              <h5 className="card-title printer-company-title">{company.name}</h5>
              <p className="card-text printer-company-text">Click to explore {company.name} printers</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      {loading ? (
        <Loader /> 
      ) : (
        <>
          <Carousel />
          <div className="container mt-4 p-5">
            <h3 className="text-center">Our Products</h3>
            {renderPrinterCompanies()}
          </div>
          <BrandLogos />
        </>
      )}
      <BackToTop/>
    </div>
  );
};

export default Sales;
