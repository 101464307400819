import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../styles/RecommendedProducts.css"; // Adjusted path

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
};

const MAX_TITLE_LENGTH = 20;
const MAX_DESCRIPTION_LENGTH = 70;

const RecommendedProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use the navigate hook

  useEffect(() => {
    setLoading(true);
    fetch("https://kingtechsolution.in/myapi/api/service.php?recommend=1")
      .then((res) => res.json())
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching recommended products:", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading recommended products...</p>;
  }

  if (error) {
    return <p>Error loading recommended products.</p>;
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, 50)}...` : text;
  };

  const renderCarouselItems = () => {
    return products.map((product) => (
      <div key={product.id} className="product-card">
        <img
          style={{ aspectRatio: "3/2 !important", objectFit: "contain" }}
          className="product-image"
          src={product.image}
          alt={product.title}
        />
        <div className="product-details">
          <h5>{truncateText(product.title, MAX_TITLE_LENGTH)}</h5>
          <p>{truncateText(product.description, MAX_DESCRIPTION_LENGTH)}</p>
          <p className="price">
            <i className="fas fa-rupee-sign"> {product.rate}</i>
            <span>
              {" "}
              <s>Rs.999</s>
            </span>
          </p>
          <Button
            variant="primary"
            onClick={() =>
              navigate(
                `/sales/${product.company}/${product.type}/${product.id}`
              )
            } // Update navigation
          >
            View Product
          </Button>
        </div>
      </div>
    ));
  };

  return (
    <div className="recommended-products-container">
      <h4>Recommended Products</h4>
      <Carousel
        responsive={responsive}
        infinite={true}
        containerClass="carousel-containers"
        itemClass="carousel-item-paddings"
      >
        {renderCarouselItems()}
      </Carousel>
    </div>
  );
};

RecommendedProducts.propTypes = {
  onProductClick: PropTypes.func.isRequired,
};

export default RecommendedProducts;
