import React from "react";
import PropTypes from "prop-types";

const PrinterList = ({ printers, onReadMoreClick }) => {
  return (
    <div className="render-printers-container">
      <h5>Available Printers</h5>
      <div className="row render-printers-scrollable">
        {printers.map((printer) => (
          <div key={printer.id} className="col-md-4 mb-3">
            <div className="card">
              {/* Updated src to match the new 'image' field */}
              <img
                src={printer.image}
                className="card-img-top"
                style={{ aspectRatio: "3/2 !important", objectFit: "contain" }}
                alt={printer.title}
              />
              <div className="card-body">
                <h6 className="card-title">
                  {/* Updated title length check and slicing */}
                  {printer.title.length > 0
                    ? `${printer.title.slice(0, 50)}...`
                    : printer.title}
                </h6>
                <p className="card-text">
                  {/* Updated description length check and slicing */}
                  {printer.description.length > 0
                    ? `${printer.description.slice(0, 50)}...`
                    : printer.description}
                </p>
                <p className="price card-text">
                  {/* Updated to use 'rate' and 'original_rate' fields */}
                  <i className="fas fa-rupee-sign"> {printer.rate}</i>
                  <span>
                    {" "}
                    <s>Rs.{printer.original_rate}</s>{" "}
                    {/* Adjusted from Rs.999 to printer.original_rate */}
                  </span>
                </p>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => onReadMoreClick(printer.id)} // Unchanged, assumes onReadMoreClick handles ID
                >
                  View
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PrinterList.propTypes = {
  printers: PropTypes.array.isRequired,
  onReadMoreClick: PropTypes.func.isRequired,
};

export default PrinterList;
