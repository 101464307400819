import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Carousel = () => {
  return (
    <div className="carousel-container">
      <div id="customCarousel" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/images/sale1.jpeg"
              className="d-block w-100"
              alt="First slide"
            />
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                height: "110px",
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(5px)",
                borderRadius: "10px",
              }}
              className="carousel-caption"
            >
              <h4> High-Performance Printers</h4>
              <p>
                Unleash productivity with our range of top-tier laser and inkjet
                printers, designed for fast, reliable, and high-quality output,
                perfect for both office and industrial use.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="/images/sale2.jpeg"
              className="d-block w-100"
              alt="First slide"
            />
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                height: "110px",
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(5px)",
                borderRadius: "10px",
              }}
              className="carousel-caption"
            >
              <h4> Custom Printing Systems</h4>
              <p>
                Tailor your printing setup with our customized solutions. From
                barcode to thermal printers, we provide the technology you need
                to optimize your workflow.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="/images/sale3.jpeg"
              className="d-block w-100"
              alt="First slide"
            />
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                height: "110px",
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(5px)",
                borderRadius: "10px",
              }}
              className="carousel-caption"
            >
              <h4> Cost-Effective Solutions</h4>
              <p>
                Explore our affordable printer options that combine efficiency
                and low maintenance, giving you the best value for your
                investment without compromising on performance.{" "}
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#customCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#customCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
