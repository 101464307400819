import React from "react";
import Map from "./Map";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate=useNavigate();

  const handleHome=()=>{
    navigate('/')
  }

  const handleServices=()=>{
    navigate('/services')
  }


  const handleAbout=()=>{
    navigate('/about')
  }

  const handleSales=()=>{
    navigate('/sales')
  }
  return (
    <div>
      {/* <!-- footer section --> */}
      <footer class="footer_section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3 footer-col">
              <div class="footer_detail">
                <h4>King Tech Solution</h4>
                <p>
                  At King Tech Solution, we are committed to delivering
                  top-notch printer hardware services and solutions. We
                  specialize in repair, maintenance, and sales of a wide range
                  of printers.
                </p>
                <div class="footer_social">
                  <a href="">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                  <a href="">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-2 mx-auto footer-col">
              <div class="footer_link_box">
                <h4>Links</h4>
                <div class="footer_links">
                  <a onClick={handleHome} class="links">
                    Home
                  </a>
                  <a onClick={handleSales} class="links">
                    Sales
                  </a>
                  <a onClick={handleServices} class="links" >
                    Services
                  </a>
                  <a onClick={handleAbout} class="links" >
                    About
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 footer-col">
              <div class="footer_contact">
                <h4>Contact Info</h4>
                <div class="contact_link_box">
                  <a href="">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                    <span>Call +91 94237 62237</span>
                  </a>
                  <a href="">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span>kingtech232@gmail.com</span>
                  </a>
                  <p>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    <span>Mon-Sat: 11.00 am - 08.00 pm</span>
                  </p>
                  <p>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    <span>Sunday: closed</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 footer-col">
              <div class="map_container">
                <div class="map">
                  {/* <!-- Embed Google Map --> */}
                  <Map />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-info">
          <div class="container">
            <p>
              &copy; <span id="displayYear"></span> All Rights Reserved By &nbsp;
              <a href="https://html.design/">King Tech Solution</a>
            </p>
          </div>
        </div>
      </footer>
      {/* <!-- footer section --> */}
    </div>
  );
}
