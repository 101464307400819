// BrandLogos.js
import React from 'react';

const BrandLogos = () => {
  return (
    <div className="brand-logos-container">
      <div className="brand-logos-wrapper">
        <div className="brand-logos">
          <img src="/images/hp.png" alt="Brand 1" />
          <img src="/images/canonlogo.png" alt="Brand 2" />
          <img src="/images/samsung.png" alt="Brand 3" />
          <img src="/images/hp.png" alt="Brand 4" />
          <img src="/images/canonlogo.png" alt="Brand 5" />
          <img src="/images/samsung.png" alt="Brand 6" />
          {/* Repeat or add more logos as needed */}
        </div>
        <div className="brand-logos">
          <img src="/images/hp.png" alt="Brand 1" />
          <img src="/images/canonlogo.png" alt="Brand 2" />
          <img src="/images/samsung.png" alt="Brand 3" />
          <img src="/images/hp.png" alt="Brand 4" />
          <img src="/images/canonlogo.png" alt="Brand 5" />
          <img src="/images/samsung.png" alt="Brand 6" />
          {/* Repeat or add more logos as needed */}
        </div>
      
      </div>
    </div>
  );
};

export default BrandLogos;
