import React from "react";
export default function Slider() {
  return (
    <div>
      {/* <!-- slider section --> */}
      <section class="slider_section ">
        <div class="slider-bg">
          <img src="images/bg11.jpg" alt="" />
        </div>
        <div id="customCarousel1" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="container ">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="detail-box">
                      <h2>
                      <i style={{fontSize:'30px'}} class="fa-solid fa-print"></i>
                      &nbsp;
                        Printer problem? <br />
                        We will fix your Printer
                      </h2>
                      <br /> <br /> <br /> <br />
                      <h4>
                        We handle the expert repair and 
                        maintenance.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="container ">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="detail-box">
                      <h2>
                      <i style={{fontSize:'30px'}} class="fa-solid fa-print"></i>
                      &nbsp;
                        Printer problem? <br />
                        We will fix your Printer
                      </h2>
                      <br /> <br /> <br /> <br />
                      <h4>Quick fixes for all your printer needs.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="container ">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="detail-box">
                      <h2>
                      <i style={{fontSize:'30px'}} class="fa-solid fa-print"></i>
                      &nbsp;
                        Printer problem? <br />
                        We will fix your Printer
                      </h2>
                      <br /> <br /> <br /> <br />
                      <h4>Your trusted partner for seamless printing.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel_btn_box">
            <a
              class="carousel-control-prev"
              href="#customCarousel1"
              role="button"
              data-slide="prev"
            >
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#customCarousel1"
              role="button"
              data-slide="next"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- end slider section --> */}
    </div>
  );
}
