import React from 'react';

const Map = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.0560053428444!2d73.78349307376456!3d20.006164022117073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddebcfa220318d%3A0xc358684c18a80da5!2sKing%20Tech%20Solutions!5e0!3m2!1sen!2sin!4v1725985266121!5m2!1sen!2sin"
      width="100%"
      height="250"
      style={{ border: "0" }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  );
};

export default Map;
