import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Service from './Components/Service';
import Layout from './Components/Layout';
import Contact from './Components/Contact';
import About from './Components/About';
import Sales from './Components/Sales';
import PrinterTypes from './Components/PrinterTypes'; 
import PrinterDetails from './Components/PrinterDetails';
import ScrollToTop from './ScrollToTop';

const App = () => {
  return (
    <Router>
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/sales/:company" element={<PrinterTypes />} /> 
          <Route path="/sales/:company/:type" element={<PrinterTypes />} />
          <Route path="/sales/:company/:type/:id" element={<PrinterDetails />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
