import React from "react";
import { useNavigate } from "react-router-dom";
import BackToTop from "./BackToTop";
export default function About() {
  const navigate = useNavigate();
  const handleSeeAll = () => {
    navigate("/services");
  };
  return (
    <div>
      <section class="about_section">
        <div class="container ">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>Who we Are ?</h2>
                </div>
                <p>
                  We <b>King Tech Solution</b>, excellence is at the core of
                  everything we do. Our mission is to deliver top-tier printer
                  solutions that meet the unique needs of each client. With a
                  team of experienced technicians and customer service experts,
                  we are committed to providing fast, reliable, and
                  cost-effective services that keep your business running
                  smoothly.
                  <br /> Whether it's repair, maintenance, installation, or
                  support, we take pride in our attention to detail and
                  dedication to quality. We believe that great service goes
                  beyond just fixing problems; it’s about building trust and
                  long-lasting relationships with our clients. That's why we go
                  the extra mile to ensure your complete satisfaction every
                  time. <br />
                  From the moment you reach out to us, you can expect a seamless
                  experience, expert guidance, and personalized solutions. Our
                  passion for innovation, combined with years of expertise,
                  ensures that you receive the best service in the industry.
                </p>
                <a href="" onClick={handleSeeAll}>
                  Read More
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="images/about-img.jpeg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="solutions_section">
        <div class="container">
          <div class="heading_container">
            <h2>Industry-Specific Printer Solutions</h2>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="solution-box">
                <h4>Retail</h4>
                <p>
                  We offer high-speed barcode and thermal printing solutions for
                  inventory management and billing in retail industries.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="solution-box">
                <h4>Healthcare</h4>
                <p>
                  Our specialized printers enable precise label printing for
                  medical devices, prescriptions, and patient records.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="solution-box">
                <h4>Corporate</h4>
                <p>
                  Robust document management systems integrated with
                  multi-function printers for large-scale businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="expertise_section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div
                class="img-box"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img
                  src="images/expertise.jpeg"
                  alt="Expertise Image"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="heading_container"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <h2>Our Expertise in Printer Technology</h2>
              </div>
              <p data-aos="fade-right" data-aos-duration="1200">
                At King Tech Solution, we specialize in providing tailored
                solutions for a wide range of printers, including laser, inkjet,
                barcode, and thermal printers. Our team is equipped to handle
                advanced repairs, installations, and maintenance for both small
                businesses and enterprise-level needs.
              </p>
              <p data-aos="fade-right" data-aos-duration="1400">
                With over a decade of experience, our certified technicians are
                equipped to troubleshoot the most complex issues, offering fast
                turnaround times without compromising on quality. Whether you're
                looking for a one-time fix, regular maintenance, or custom
                solutions, our team delivers with excellence.
                <br />
              </p>
              <p>
                We stay ahead of industry trends, constantly upgrading our
                knowledge and skills, so you receive cutting-edge solutions at
                every turn.
              </p>
            </div>
          </div>
        </div>
      </section>
      <BackToTop />
    </div>
  );
}
