import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Tabs, Card, Button } from "react-bootstrap";
import "../styles/PrinterDetails.css";
import BackToTop from "./BackToTop";

const PrinterDetails = () => {
  const { id } = useParams();
  const [printer, setPrinter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate(); // For navigating to a different product

  useEffect(() => {
    const fetchPrinterDetails = async () => {
      setLoading(true);
      const apiEndpoint = `https://kingtechsolution.in/myapi/api/service.php?fetch_all=true&id=${id}`;
      try {
        const response = await fetch(apiEndpoint);
        const data = await response.json();

        console.log("API Data:", data); // Log the data for debugging

        if (data && data.id) {
          setPrinter({
            id: data.id,
            title: data.title,
            category: data.subtype, // Assuming subtype is used as category
            description: data.description,
            price: data.rate,
            image: data.image,
            img2: data.img2,
            img3: data.img3,
            img4: data.img4,
            model: data.Model,
            original_rate: data.original_rate,
          });
          setSelectedImage(data.image);

          const relatedResponse = await fetch(
            `https://kingtechsolution.in/myapi/api/service.php?fetch_all=true`
          );
          const allProducts = await relatedResponse.json();

          const filteredProducts = allProducts.filter(
            (product) =>
              product.subtype === data.subtype &&
              product.product_name === data.product_name &&
              product.id !== parseInt(id)
          );
          setRelatedProducts(filteredProducts.slice(0, 3));
        } else {
          console.error("Printer not found");
        }
      } catch (error) {
        console.error("Error fetching printer details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrinterDetails();
  }, [id]);

  // Fetch details whenever the `id` changes

  if (loading) return <p>Loading details...</p>;
  if (!printer) return <p>No printer details available at the moment.</p>;

  return (
    <div className="container mt-4 p-4">
      <div className="row">
        <div className="col-md-6">
          {/* Main Image */}
          <div className="main-image">
            <img src={selectedImage} alt="Main Product" />
          </div>

          {/* Thumbnail Images */}
          <div className="thumbnail-container">
            {[printer.image, printer.img2, printer.img3, printer.img4].map(
              (img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className={selectedImage === img ? "selected" : ""}
                  onClick={() => setSelectedImage(img)}
                />
              )
            )}
          </div>
        </div>

        <div className="col-md-6">
          <h3>{printer.title}</h3>
          <p className="text-muted">{printer.category}</p>
          <h5 className="text-primary">
            <i className="fa-solid fa-indian-rupee-sign"></i>
            {printer.price}
            <span className="price">
              {" "}
              <s>{printer.original_rate}</s>{" "}
            </span>
          </h5>
          <p>{printer.description}</p>
          <Button
            variant="primary"
            onClick={() => {
              const phoneNumber = "919423762237"; // Replace with your WhatsApp number
              const message = `Hello, I am interested in purchasing the printer ${printer.title} with model number ${printer.model}. Could you please provide more details?`;
              const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                message
              )}`;
              window.open(whatsappURL, "_blank"); // Open WhatsApp link in new tab
            }}
          >
            Buy Now
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <h4>Product Specifications</h4>
        <ul className="list-group">
          <li className="list-group-item">
            <strong>Brand:</strong> {printer.title}
          </li>
          <li className="list-group-item">
            <strong>Model:</strong> {printer.model}{" "}
            {/* Placeholder, change as needed */}
          </li>
          <li className="list-group-item">
            <strong>Features:</strong> High resolution, Wireless connectivity,
            etc. {/* Placeholder, change as needed */}
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <h4>Customer Reviews</h4>
        <div className="list-group">
          <div className="list-group-item">
            <h5>Kalpesh Shirude</h5>
            <p className="text-muted">★★★★☆</p>
            <p>Great printer, high quality prints. Worth the price!</p>
          </div>
          <div className="list-group-item">
            <h5>Abhi Nikam</h5>
            <p className="text-muted">★★★☆☆</p>
            <p>Good printer, but the setup was a bit complicated.</p>
          </div>
        </div>
      </div>

      {/* Related Products */}
      <div className="mt-4">
        <h4>Related Products</h4>
        <div className="row justify-content-center">
          {relatedProducts.length > 0 ? (
            relatedProducts.map((product) => (
              <div
                key={product.id}
                className="col-md-4 mb-4 d-flex justify-content-center"
              >
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    style={{
                      height: "200px",
                      objectFit: "contain",
                    }}
                    variant="top"
                    src={product.image}
                  />
                  <Card.Body>
                    {/* Slice title and description */}
                    <Card.Title>{product.title.slice(0, 15)}...</Card.Title>
                    <Card.Text>{product.description.slice(0, 50)}...</Card.Text>
                    <Card.Text>${product.rate}</Card.Text>
                    {/* Navigate to the clicked related product */}
                    <button
                      className="btn btn-primary w-100"
                      variant="secondary"
                      onClick={() =>
                        navigate(
                          `/sales/${product.company}/${product.type}/${product.id}`
                        )
                      }
                    >
                      View Details
                    </button>
                  </Card.Body>
                </Card>
              </div>
            ))
          ) : (
            <p>No related products available.</p>
          )}
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default PrinterDetails;
