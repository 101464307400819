// Home.js
import React from "react";
import Service from "./Service";
import About from "./About";
import Why from "./Why";
import Contact from "./Contact";
import Footer from "./Footer";
import Slider from "./Slider";
import BackToTop from "./BackToTop";

export default function Home() {
  return (
    <div className="hero_area">
      <Slider />
      <Service />
      <About />
      <Contact />
      <BackToTop />
    </div>
  );
}
