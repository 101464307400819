import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PrinterTypeSelector from "./PrinterTypeSelector";
import PrinterList from "./PrinterList";
import RecommendedProducts from "./RecommendedProducts";
import TopDeals from "./TopDeals"; // Import the new component

// Define API endpoints and printer companies at the top of the file
const API_ENDPOINTS = {
  hp: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=hp&productSubtype=scanjet",
  },
  canon: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=canon&productSubtype=scanjet",
  },
  epson: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=epson&productSubtype=scanjet",
  },
  ricoh: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=ricoh&productSubtype=scanjet",
  },
  sharp: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=sharp&productSubtype=scanjet",
  },
  toshiba: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=toshiba&productSubtype=scanjet",
  },
  samsung: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=samsung&productSubtype=scanjet",
  },
  konica_minolta: {
    Laserjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=laserjet",
    Inkjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=ink+jet",
    Barcode:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=barcode",
    DotMatrix:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=dot+matrix",
    Thurmul:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=thurmul",
    Scanjet:
      "https://kingtechsolution.in/myapi/api/service.php?productname=konica+minolta&productSubtype=scanjet",
  },
};

const PRINTER_COMPANIES = [
  {
    name: "HP Printers",
    id: "hp",
    description:
      "HP offers a range of high-performance Laserjet and Inkjet printers designed for personal and business use.",
  },
  {
    name: "Canon Printers",
    id: "canon",
    description:
      "Canon provides innovative and reliable office and photo printers, catering to a wide variety of printing needs.",
  },
  {
    name: "Epson Printers",
    id: "epson",
    description:
      "Epson specializes in high-quality Inkjet and all-in-one printers, suitable for homes and small businesses.",
  },
  {
    name: "Brother Printers",
    id: "brother",
    description:
      "Brother produces fast, cost-effective, and reliable printing solutions for business environments.",
  },
  {
    name: "Samsung Printers",
    id: "samsung",
    description:
      "Samsung delivers innovative printing technology for home and office, focusing on performance and ease of use.",
  },
  {
    name: "Ricoh Printers",
    id: "ricoh",
    description:
      "Ricoh offers advanced and reliable printing solutions, with a focus on sustainability and efficiency.",
  },
  {
    name: "Sharp Printers",
    id: "sharp",
    description:
      "Sharp delivers high-performance printers that combine productivity with ease of use.",
  },
  {
    name: "Toshiba Printers",
    id: "toshiba",
    description:
      "Toshiba offers durable and efficient printers for businesses of all sizes, focusing on security and eco-friendliness.",
  },
  {
    name: "Konica Minolta Printers",
    id: "konica_minolta",
    description:
      "Konica Minolta provides innovative printing technology for offices, with a focus on quality and speed.",
  },
];

const PrinterTypes = () => {
  const { company, type } = useParams();
  const navigate = useNavigate();

  const [printers, setPrinters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(type || null);
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    if (company && selectedType) {
      setLoading(true);
      const apiEndpoint = API_ENDPOINTS[company]?.[selectedType];
      fetch(apiEndpoint)
        .then((res) => res.json())
        .then((data) => {
          setPrinters(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching printers:", error);
          setLoading(false);
        });
    }
  }, [company, selectedType]);

  useEffect(() => {
    const companyInfo =
      PRINTER_COMPANIES.find((comp) => comp.id === company)?.description ||
      "No information available.";
    setCompanyInfo(companyInfo);
  }, [company]);

  const handleTypeClick = (type) => {
    setSelectedType(type);
    navigate(`/sales/${company}/${type}`);
  };

  const handleReadMoreClick = (id) => {
    navigate(`/sales/${company}/${selectedType}/${id}`);
  };

  const handleProductClick = (id) => {
    navigate(`/products/${id}`);
  };

  return (
    <div className="container p-4 mt-4">
      <div className="row">
        <div className="col-lg-3 col-12">
          <PrinterTypeSelector
            selectedType={selectedType}
            onTypeClick={handleTypeClick}
          />
        </div>
        <div className="col-lg-9 col-12">
          <h3>
            {selectedType
              ? `${selectedType} Printers`
              : `About ${
                  PRINTER_COMPANIES.find((comp) => comp.id === company)?.name
                }`}
          </h3>
          {selectedType ? (
            <>
              <p>{companyInfo}</p>
              {loading ? (
                <p>Loading printers...</p>
              ) : (
                <PrinterList
                  printers={printers}
                  onReadMoreClick={handleReadMoreClick}
                />
              )}
            </>
          ) : (
            <p>Select a printer type to view available printers.</p>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <RecommendedProducts onProductClick={handleProductClick} />
        </div>
      </div>
    </div>
  );
};

export default PrinterTypes;
